<i18n lang="yaml">
ru:
  all_products: 'Показать все товары'
  all: 'Все'
en:
  all_products: 'View all products'
  all: 'All'
</i18n>

<template>
  <div class="container tree-mobile">
    <div class="catalog-header">
      <h1>{{ getCategoryLocaleData(rootCategory).name }}</h1>
      <nuxt-link v-if="!isAllProdsPath" :to="localePath('/catalog/')" @click.native="closeCat">{{ $t('all_products') }}</nuxt-link>
      <span v-else @click="closeCat">{{ $t('all_products') }}</span>
    </div>

    <div class="tree-items">

      <div class="search-file">
        <products-search-input @search-input="closeCat" />
      </div>

      <div class="catalog-square">
        <template v-for="sub1 in rootCategory.subcategories">
          <div class="catalog-square-item" @click="action = sub1.id; showChild = true">
            <div class="catalog-square-item__container">
                <span class="cat-square-item__header"
                      :class="{'active': action === sub1.id }">{{ getCategoryLocaleData(sub1).name }}</span>
              <nuxt-link class="cat-square-item__all" v-if="sub1.subcategories.length > 1"
                         :to="localePath(`/${rootCategory.slug}/${sub1.slug}/`)" @click.native="closeCat">{{ $t('all') }}
              </nuxt-link>
              <i class="svg-icon arrow-mobile"></i>
            </div>
          </div>
        </template>
        <nuxt-link class="favourites" v-show="favouriteProductsExists" :to="localePath('/products/favourites/')"
                   :aria-label="$t('favourites')">
          <i class="svg-icon star icon-brand"></i><span @click="closeCat">Избранное</span>
        </nuxt-link>
      </div>

      <div class="catalog-rendered-items">
        <template v-for="sub1 in rootCategory.subcategories">
          <transition :name="slideTransition">
            <div class="rendered_value" v-show="action === sub1.id" :class="{'hidden': action !== sub1.id}">
              <div class="name-category" @click="action = null; showChild = false">
                <i class="svg-icon arrow-mobile"></i>
                <h3>{{ getCategoryLocaleData(sub1).name }}</h3>
              </div>
              <div class="pre-items">
                <div class="items">
                  <template v-for="sub2 in sub1.subcategories">

                    <nuxt-link :to="localePath(`/${rootCategory.slug}/${sub1.slug}/${sub2.slug}/`)">
                        <span class="catalog-square-item__link" @click="closeCat">{{
                            getCategoryLocaleData(sub2).name
                          }}</span>
                    </nuxt-link>

                  </template>
                </div>
              </div>
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsSearchInput from "@/components/catalog/products-search-input.vue";

export default {
  props: ['categories'],
  components: {ProductsSearchInput},
  data() {
    return {
      action: null,
      slideTransition: null,
    }
  },
  computed: {
    rootCategory() {
      // if (this.categories[0] && this.categories[0].subcategories && this.categories[0].subcategories.length) {
      //   this.action = this.categories[0].subcategories[0].id
      // }
      return this.categories[0];
    },
    isAllProdsPath() {
      return this.$route.path === this.localePath('/catalog/');
    },
    favouriteProductsExists() {
      return this.$auth.loggedIn && this.$store.getters.favouriteProductsExists;
    },
  },
  methods: {
    getCategoryLocaleData(category) {
      return category.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    closeCat() {
      this.$emit('toggle-cat');
    },
  }
}

</script>

